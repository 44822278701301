<template>
  <v-select
    v-bind="$attrs"
    item-value="canonical"
    item-text="name"
    :menu-props="{ offsetY: true }"
    class="cy-credential"
    hide-selected
    persistent-hint
    data-cy="credential-select"
    v-on="$listeners">
    <template #selection="data">
      <v-avatar
        :key="data.item.type"
        size="32">
        <CyIconCredential
          medium
          :type="data.item.type"/>
      </v-avatar>
      <div class="black--text">
        {{ data.item.name }}
      </div>
    </template>

    <template #item="data">
      <v-list-item-avatar>
        <CyIconCredential
          medium
          :type="data.item.type"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CyCredentials',
}
</script>

<style lang="scss" scoped>
.cy-credential ::v-deep .v-input__append-inner {
  align-self: center;
}
</style>
